import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Header, Body, LogoDownload, Markdown } from "../styles"

const text = ({ data }) => {
  const text = data.strapiTexts

  return (
    <Layout>
      <SEO title="Texts" />
      <Header center>{text.title}</Header>

      <Markdown>
        <ReactMarkdown source={text.text} escapeHtml={false} />
      </Markdown>

      <Body center>
        Download PDF:
        <a href={text.pdf.publicURL} download={text.title}>
          <LogoDownload />
        </a>
      </Body>
    </Layout>
  )
}

export default text

export const textQuery = graphql`
  query($location: String!) {
    strapiTexts(title: { eq: $location }) {
      title
      text
      pdf {
        publicURL
      }
    }
  }
`
